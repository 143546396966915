import ArrowTopRightOnSquareIcon from "@heroicons/react/24/solid/ArrowTopRightOnSquareIcon";
import ChevronUpDownIcon from "@heroicons/react/24/solid/ChevronUpDownIcon";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Stack,
  SvgIcon,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import useAuth from "../hooks/useAuth";
import { generateItems } from "./config";
import { Logo } from "./logo";
import { Scrollbar } from "./scrollbar";
import { SideNavItem } from "./side-nav-item";

interface SideNavProps {
  open: boolean;
  onClose: () => void;
}

export const SideNav: FC<SideNavProps> = ({ open, onClose }) => {
  const location = useLocation();
  const theme = useTheme();
  const pathname = location.pathname;
  const { user } = useAuth();
  const lgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const items = generateItems();

  const redirectToPgAdmin = () => {
    const baseUrl = window.location.origin;
    const pgAdminUrl = `${baseUrl}/pgadmin4`;
    window.open(pgAdminUrl, "_blank");
  };

  const content = (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "inline-flex",
            height: 32,
            width: 32,
          }}
        >
          <Link
            to="/"
            style={{ display: "inline-flex", height: 32, width: 32 }}
          >
            <Logo />
          </Link>
        </Box>
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "rgba(255, 255, 255, 0.04)",
            borderRadius: 1,
            cursor: "pointer",
            display: "flex",
            justifyContent: "space-between",
            mt: 2,
            p: "12px",
          }}
        >
          <div>
            <Typography color="inherit" variant="subtitle1">
              {user?.nome}
            </Typography>
            <Typography color="neutral.400" variant="body2">
              {user?.tipo === "ADMIN" ? "Admin" : "Revenda"}
            </Typography>
            <Typography color="neutral.400" variant="body2">
              Vencimento: {user?.dataVencimento}
            </Typography>
          </div>
          <SvgIcon fontSize="small" sx={{ color: "neutral.500" }}>
            <ChevronUpDownIcon />
          </SvgIcon>
        </Box>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        component="nav"
        sx={{
          flexGrow: 1,
          px: 2,
          py: 3,
        }}
      >
        <Stack
          component="ul"
          spacing={0.5}
          sx={{
            listStyle: "none",
            p: 0,
            m: 0,
          }}
        >
          {items.map((item) => {
            const active = item.path ? pathname === item.path : false;

            return (
              <SideNavItem
                active={active}
                disabled={item.disabled}
                external={item.external}
                icon={item.icon}
                key={item.title}
                path={item.path}
                title={item.title}
              />
            );
          })}
        </Stack>
      </Box>
      <Divider sx={{ borderColor: "neutral.700" }} />
      <Box
        sx={{
          px: 2,
          py: 3,
        }}
      >
        <Typography color="neutral.500" variant="body2">
          Versão: 1.0.5
        </Typography>
        <Typography color="neutral.500" variant="body2">
          <a
            href="https://www.instagram.com/techsoft.tecnologia/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "inherit", textDecoration: "none" }}
          >
            @techsoft.tecnologia
          </a>
        </Typography>
        {user?.tipo === "ADMIN" && (
          <>
            <Button
              component="a"
              endIcon={
                <SvgIcon fontSize="small">
                  <ArrowTopRightOnSquareIcon />
                </SvgIcon>
              }
              fullWidth
              onClick={redirectToPgAdmin}
              sx={{ mt: 2 }}
              target="_blank"
              variant="contained"
            >
              Banco de dados
            </Button>
          </>
        )}
      </Box>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: "neutral.800",
            color: "common.white",
            width: 280,
          },
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: "neutral.800",
          color: "common.white",
          width: 280,
        },
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      <Scrollbar
        sx={{
          height: "100%",
          "& .simplebar-content": {
            height: "100%",
          },
          "& .simplebar-scrollbar:before": {
            background: "neutral.400",
          },
        }}
      >
        {content}
      </Scrollbar>
    </Drawer>
  );
};

export default SideNav;
