import axios from "axios";
import React, {
  createContext,
  useEffect,
  useState,
  ReactNode,
  FC,
} from "react";
import { toast } from "react-toastify";
import UAParser from "ua-parser-js";

import { getApiBaseUrl } from "../services/baseUrl";
import { getClientInfo } from "../services/clientInfo";
import { getMe } from "../services/usuario";
import { AxiosError, AxiosResponse, Me } from "../types";

interface User {
  idUsuario: string | null;
  dataVencimento: string;
  nome: string;
  tipo: string;
}

interface SignInResponse {
  success: boolean;
  message?: string;
}

interface LoginResponse {
  token: string;
}

interface AuthContextType {
  user: User | null;
  editUser: User | null;
  signed: boolean;
  loading: boolean;
  signin: (
    usuario: string,
    senha: string,
    cliente: string | null,
    token: string | null,
    tipo: "ADMIN" | "REVENDA",
    isPanelActive: boolean,
  ) => Promise<SignInResponse>;
  signout: () => void;
  setEditUser: (user: User | null) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

export type { AuthContextType };

export const AuthContext = createContext<AuthContextType>({
  user: null,
  editUser: null,
  signed: false,
  loading: true,
  signin: async () => ({ success: false, errorMessage: "Default error" }),
  signout: () => {},
  setEditUser: () => {},
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const API_BACK = getApiBaseUrl();
  const API_KEY_GENERATOR = process.env.REACT_APP_KEY_GENERATOR;
  const [user, setUser] = useState<User | null>(null);
  const [editUser, setEditUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  const signout = () => {
    setUser(null);
    sessionStorage.removeItem("user_token");
  };

  useEffect(() => {
    const fetchUser = async () => {
      const userToken = sessionStorage.getItem("user_token");

      if (userToken && userToken !== "") {
        const formattedToken = userToken.replace(/^"|"$/g, "");

        try {
          const response = await getMe(formattedToken);
          if (response) {
            const { idUsuario, nome, dataVencimento, tipo } = response.data;
            setUser({ idUsuario, nome, dataVencimento, tipo });
          }
        } catch (error) {
          toast.info("Não foi possível fazer a requisição /me:");
          signout();
        } finally {
          setLoading(false);
        }
      } else {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const fetchClientIp = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      const data = await response.json();
      return data.ip;
    } catch (error) {
      console.error("Erro ao obter o endereço IP:", error);
      return "IP desconhecido";
    }
  };

  const signin = async (
    usuario: string,
    senha: string,
    cliente: string | null,
    token: string | null,
    tipo: "ADMIN" | "REVENDA",
    isPanelActive: boolean,
  ): Promise<SignInResponse> => {
    try {
      if (tipo === "ADMIN") {
        if (isPanelActive) {
          // Painel ativo e aba admin
          try {
            const { ip, navegador, versao, plataforma } = await getClientInfo();

            const response = await axios.post(
              `${API_BACK}/v1/login`,
              {
                nome: usuario,
                senha: senha,
                ip: ip,
                navegador: navegador,
                versao: versao,
                plataforma: plataforma,
              },
              {
                params: {
                  tipoUsuario: tipo,
                },
              },
            );

            const { idUsuario, dataVencimento, token } = response.data;
            setUser({ idUsuario, nome: usuario, dataVencimento, tipo });
            sessionStorage.setItem("user_token", JSON.stringify(token));
            return { success: true, message: "" };
          } catch (error) {
            let errorMessage = "Erro interno no login";

            if (axios.isAxiosError(error)) {
              if (
                error.response &&
                error.response.data &&
                error.response.data.response
              ) {
                errorMessage = error.response.data.response;
              } else if (error.message) {
                errorMessage = error.message;
              }
            } else if (error instanceof Error) {
              errorMessage = error.message;
            }

            return { success: false, message: errorMessage };
          }
        } else {
          // Painel inativo e aba admin
          try {
            const isLocalhost = window.location.hostname === "localhost";

            // if (!isLocalhost) {
            //   const parser = new UAParser();
            //   const result = parser.getResult();
            //   const userAgentInfo = {
            //     browser: result.browser.name,
            //     os: result.os.name,
            //     device: result.device.model || "Unknown",
            //   };

            //   const clientIp = await fetchClientIp();

            //   const responseToken = await axios.post<LoginResponse>(
            //     `${API_KEY_GENERATOR}/use-token`,
            //     {
            //       name: cliente,
            //       ip: clientIp,
            //       browser: userAgentInfo.browser,
            //       os: userAgentInfo.os,
            //       device: userAgentInfo.device,
            //       token: token,
            //     },
            //   );

            //   console.log("Primeiro POST bem-sucedido:", responseToken);
            // }

            const responseReceiveToken = await axios.post(
              `${API_BACK}/v1/receiveToken`,
              {
                token,
              },
            );

            console.log(
              "POST /v1/receiveToken bem-sucedido:",
              responseReceiveToken,
            );

            const { ip, navegador, versao, plataforma } = await getClientInfo();

            const terceiroResponse = await axios.post(
              `${API_BACK}/v1/login`,
              {
                nome: usuario,
                senha: senha,
                ip: ip,
                navegador: navegador,
                versao: versao,
                plataforma: plataforma,
              },
              {
                params: {
                  tipoUsuario: tipo,
                },
              },
            );

            if (terceiroResponse.status === 200) {
              const { idUsuario, dataVencimento, token } =
                terceiroResponse.data;
              setUser({ idUsuario, nome: usuario, dataVencimento, tipo });
              console.log("terceiro POST bem-sucedido:", terceiroResponse);
              sessionStorage.setItem("user_token", JSON.stringify(token));
              return { success: true, message: "" };
            } else {
              return {
                success: false,
                message: "Login falhou no segundo POST",
              };
            }
          } catch (error) {
            let errorMessage = "Erro desconhecido";

            if (axios.isAxiosError(error)) {
              const serverError = error as AxiosError;

              if (serverError.response) {
                const responseData = serverError.response.data as {
                  response?: string;
                };
                errorMessage = responseData.response || "Erro no servidor";
              }
            } else if (error instanceof Error) {
              // Trata erros genéricos do JavaScript
              errorMessage = error.message;
            }

            return {
              success: false,
              message: errorMessage,
            };
          }
        }
      }
    } catch (error) {
      console.error("Signin error:", error);
      return {
        success: false,
        message: "Erro no processo de login",
      };
    }
    try {
      if (tipo === "REVENDA") {
        if (isPanelActive) {
          try {
            const { ip, navegador, versao, plataforma } = await getClientInfo();

            const response = await axios.post(
              `${API_BACK}/v1/login`,
              {
                nome: usuario,
                senha: senha,
                ip: ip,
                navegador: navegador,
                versao: versao,
                plataforma: plataforma,
              },
              {
                params: {
                  tipoUsuario: tipo,
                },
              },
            );

            const { idUsuario, dataVencimento, token } = response.data;
            setUser({ idUsuario, nome: usuario, dataVencimento, tipo });
            sessionStorage.setItem("user_token", JSON.stringify(token));
            return { success: true, message: "" };
          } catch (error) {
            let errorMessage = "Erro desconhecido";

            if (axios.isAxiosError(error)) {
              const serverError = error as AxiosError;

              if (serverError.response) {
                const responseData = serverError.response.data as {
                  response?: string;
                };
                errorMessage = responseData.response || "Erro no servidor";
              }
            } else if (error instanceof Error) {
              // Trata erros genéricos do JavaScript
              errorMessage = error.message;
            }

            return {
              success: false,
              message: errorMessage,
            };
          }
        } else {
          toast.error("Painel Inativo");
        }
      }
    } catch (error) {
      console.error("Signin error revenda:", error);
      return {
        success: false,
        message: "Erro no processo de login da revenda",
      };
    }
    // Retorno padrão caso não entre nos IFs
    return {
      success: false,
      message: "Tipo de usuário inválido ou painel inativo",
    };
  };

  const handleSetEditUser = (user: User | null) => {
    setEditUser(user);
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        editUser,
        signed: !!user,
        loading,
        signin,
        signout,
        setEditUser: handleSetEditUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
